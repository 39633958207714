exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-haccp-2023-js": () => import("./../../../src/pages/haccp2023.js" /* webpackChunkName: "component---src-pages-haccp-2023-js" */),
  "component---src-pages-haccp-js": () => import("./../../../src/pages/haccp.js" /* webpackChunkName: "component---src-pages-haccp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mycawan-staff-employee-invitation-js": () => import("./../../../src/pages/mycawan_staff/employee-invitation.js" /* webpackChunkName: "component---src-pages-mycawan-staff-employee-invitation-js" */),
  "component---src-pages-mycawan-staff-index-js": () => import("./../../../src/pages/mycawan_staff/index.js" /* webpackChunkName: "component---src-pages-mycawan-staff-index-js" */),
  "component---src-pages-netrestomobile-js": () => import("./../../../src/pages/netrestomobile.js" /* webpackChunkName: "component---src-pages-netrestomobile-js" */),
  "component---src-pages-pointeuse-js": () => import("./../../../src/pages/pointeuse.js" /* webpackChunkName: "component---src-pages-pointeuse-js" */),
  "component---src-pages-testing-js": () => import("./../../../src/pages/testing.js" /* webpackChunkName: "component---src-pages-testing-js" */)
}

